import { Dialog, Transition } from "@headlessui/react";
import React, { forwardRef, useImperativeHandle, useState } from "react";
import { FiX } from "react-icons/fi";

export const Modal = forwardRef(({ children, size }, ref) => {
	const [open, setOpen] = useState(false);

	useImperativeHandle(
		ref,
		() => {
			return {
				open(data) {
					setOpen(true);
				},
				close() {
					setOpen(false);
				},
			};
		},
		[]
	);

	return (
		<Transition appear show={open} as={React.Fragment}>
			<Dialog
				as="div"
				className="relative z-[999]"
				onClose={() => setOpen(false)}
			>
				<Transition.Child
					as={React.Fragment}
					enter="ease-out duration-300"
					enterFrom="opacity-0"
					enterTo="opacity-100"
					leave="ease-in duration-200"
					leaveFrom="opacity-100"
					leaveTo="opacity-0"
				>
					<div className="fixed inset-0 bg-black bg-opacity-25" />
				</Transition.Child>

				<div className="fixed inset-0 overflow-y-auto">
					<div className="flex items-center justify-center min-h-full p-4 text-center">
						<Transition.Child
							as={React.Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0 scale-95"
							enterTo="opacity-100 scale-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100 scale-100"
							leaveTo="opacity-0 scale-95"
						>
							<Dialog.Panel
								className={`w-full p-5 relative ${
									size === "lg"
										? "max-w-4xl"
											: size === "md"
											? "max-w-3xl"
										: "max-w-md"
								} overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-[30px]`}
							>
								<div
									onClick={() => setOpen(false)}
									className="flex cursor-pointer justify-end items-end"
								>
									<FiX size={22} />
								</div>
								{children}
							</Dialog.Panel>
						</Transition.Child>
					</div>
				</div>
			</Dialog>
		</Transition>
	);
});